import { tidy, filter } from '@tidyjs/tidy';
import { indexOf } from 'lodash';

export function analysisFilter(data, dimension, value) {
	const resultingList = tidy(
		data,
		filter(d => {
			const dataType = typeof d[dimension];
			if (dataType === 'string' || dataType === 'number') {
				// For single choice filter items
				return d[dimension] === value;
			} else if (dataType === 'object') {
				// For multiple choice filter items
				return indexOf(d[dimension], value) > -1;
			}
		}),
	);

	return resultingList;
}

function rankingQuestionRowFilter(data, dimension, value, rank) {
	return data.filter(
		d =>
			d[dimension] &&
			d[dimension].find(el => el.choice === value).order === rank,
	);
}

function matrixQuestionRowFilter(data, dimension, value, colVal) {
	return data.filter(d => {
		if (d[dimension]) {
			const choice = d[dimension].find(el => el.choice === value);
			return (
				choice && choice.columns.some(choiceVal => choiceVal.choice === colVal)
			);
		}
	});
}

function rankingQuestionColumnFilter(data, dimension, value, length) {
	const counts = {};
	Array.from({ length: length }, (_, i) => {
		counts[i + 1] = 0;
	});
	data.forEach(d => {
		if (d[dimension]) {
			const filteredValue = d[dimension].find(el => el.choice === value);

			counts[filteredValue.order] = counts[filteredValue.order] + 1;
		}
	});
	return counts;
}

function matrixQuestionColumnFilter(data, dimension, value, cols) {
	const counts = {};

	Array.from({ length: cols.length }, (_, i) => {
		counts[cols[i].nanoid] = 0;
	});

	data.forEach(d => {
		if (d[dimension]) {
			const filteredValue = d[dimension].find(el => el.choice === value);

			if (filteredValue) {
				filteredValue.columns.forEach(col => {
					counts[col.choice] = counts[col.choice] + 1;
				});
			}
		}
	});
	return counts;
}

export function createColumnForCrossTabTable(
	arr,
	calculatedValues,
	rowFilterItem,
	choice,
	rowRank,
	matrixRowColValue,
) {
	const columnValues = [];

	let index = 0;

	arr.crossTab.columns.forEach(columnFilterItem => {
		let matrixTotal = 0;
		let total = 0;
		let rankingDivider =
			columnFilterItem.question_type === 'ranking'
				? columnFilterItem.categoryfield_set.length
				: columnFilterItem.question_type === 'matrix'
				? columnFilterItem.categoryfield_set.length
				: 1;

		columnFilterItem.categoryfield_set.forEach(columnChoice => {
			let rankingLoopLength =
				columnFilterItem.question_type === 'ranking'
					? columnFilterItem.categoryfield_set.length
					: columnFilterItem.question_type === 'matrix'
					? columnFilterItem.columns.length
					: 1;

			//create rank rows for ranking question by looping the number of ranking choices
			Array.from({ length: rankingLoopLength }, (_, i) => {
				let matrixTot = 0;
				if (columnFilterItem.question_type === 'matrix') {
					matrixTot =
						rowFilterItem.question_type === 'ranking'
							? rankingQuestionRowFilter(
									arr.filteredData,
									rowFilterItem.slug,
									choice.nanoid,
									parseInt(i + 1),
							  )
							: rowFilterItem.question_type === 'matrix'
							? matrixQuestionRowFilter(
									arr.filteredData,
									rowFilterItem.slug,
									choice.nanoid,
									rowFilterItem.columns[i].nanoid,
							  )
							: analysisFilter(
									arr.filteredData,
									rowFilterItem.slug,
									choice.nanoid,
							  );
				}
				const matrixColumnNanoid = columnFilterItem.columns
					? columnFilterItem.columns[i].nanoid
					: null;

				let columnRank = (rankingLoopLength =
					columnFilterItem.question_type === 'ranking' ? i + 1 : 0);
				const columnTotal = calculatedValues
					.filter(
						col =>
							col.columnChoiceNanoid === columnChoice.nanoid &&
							col.columnNanoid === columnFilterItem.nanoid &&
							col.rowNanoid === rowFilterItem.nanoid &&
							col.rowRankId === rowRank &&
							col.columnRankId === columnRank &&
							col.matrixColColNanoid === matrixColumnNanoid,
					)
					.reduce((acc, curr) => acc + curr.count, 0);

				const rowTotal = calculatedValues
					.filter(
						col =>
							col.rowChoiceNanoid === choice.nanoid &&
							col.columnNanoid === columnFilterItem.nanoid &&
							col.rowRankId === rowRank &&
							col.columnRankId === columnRank &&
							col.matrixRowColNanoid === matrixRowColValue,
					)
					.reduce((acc, curr) => acc + curr.count, 0);

				let colDivider =
					columnFilterItem.question_type === 'ranking'
						? columnFilterItem.categoryfield_set.length
						: columnFilterItem.question_type === 'matrix'
						? columnFilterItem.categoryfield_set.length
						: 1;

				let rowDivider =
					rowFilterItem.question_type === 'ranking'
						? rowFilterItem.categoryfield_set.length
						: rowFilterItem.question_type === 'matrix'
						? rowFilterItem.categoryfield_set.length
						: 1;

				let totalTotal =
					calculatedValues
						.filter(
							col =>
								col.rowNanoid === rowFilterItem.nanoid &&
								col.columnNanoid === columnFilterItem.nanoid,
						)
						.reduce((acc, curr) => acc + curr.count, 0) /
					(rowDivider * colDivider);

				if (
					columnFilterItem.question_type === 'matrix' &&
					columnFilterItem.isMultiselection
				) {
					const multiselectionMatrixTotal = arr.filteredData.filter(
						d => d[columnFilterItem.nanoid] && d[rowFilterItem.nanoid],
					).length;
					totalTotal = multiselectionMatrixTotal;
				}

				const columnCount = calculatedValues.find(
					col =>
						col.columnChoiceNanoid === columnChoice.nanoid &&
						col.columnNanoid === columnFilterItem.nanoid &&
						col.rowNanoid === rowFilterItem.nanoid &&
						col.rowChoiceNanoid === choice.nanoid &&
						col.columnRankId === columnRank &&
						col.rowRankId === rowRank &&
						col.matrixColColNanoid === matrixColumnNanoid &&
						col.matrixRowColNanoid === matrixRowColValue,
				).count;
				const totalColumn =
					rowFilterItem.question_type === 'matrix'
						? columnTotal / rowFilterItem.categoryfield_set.length
						: columnTotal;

				const totalRow =
					columnFilterItem.question_type === 'matrix'
						? matrixTot.length
						: rowTotal;

				columnValues.push({
					nanoid: `${columnFilterItem.slug}-${
						columnChoice.nanoid
					}-${columnRank}-${matrixRowColValue ? matrixRowColValue : i}`,
					name: `${columnChoice.nanoid}-${columnRank}-${
						matrixRowColValue ? matrixRowColValue : i
					}`,
					count: columnCount,
					totalPercentage: (columnCount / totalTotal) * 100,
					columnPercentage:
						columnCount > 0 ? (columnCount / totalColumn) * 100 : 0,
					rowPercentage: rowTotal > 0 ? (columnCount / totalRow) * 100 : 0,
				});
				total = total + columnCount;
				matrixTotal = matrixTotal + matrixTot.length;
			});
		});

		columnValues.push({
			nanoid: index++,
			name: 'Total',
			count:
				columnFilterItem.question_type === 'matrix'
					? matrixTotal / (rankingDivider * columnFilterItem.columns.length)
					: total / rankingDivider,
		});
	});
	return columnValues;
}

export function calculateRowColumnData(columns, rows, rawData) {
	let calculatedValues = [];
	columns.forEach(columnFilterItem => {
		columnFilterItem.categoryfield_set.map(columnChoice => {
			rows.forEach(rowFilterItem => {
				rowFilterItem.categoryfield_set.map(rowChoice => {
					let rankingLoopLength = 1;
					if (rowFilterItem.question_type === 'ranking') {
						rankingLoopLength = rowFilterItem.categoryfield_set.length;
					} else if (
						rowFilterItem.question_type === 'matrix' &&
						rowFilterItem.categoryfield_set.length > 0
					) {
						rankingLoopLength = rowFilterItem.columns.length;
					}
					Array.from({ length: rankingLoopLength }, (_, i) => {
						const rowResponses =
							rowFilterItem.question_type === 'ranking'
								? rankingQuestionRowFilter(
										rawData,
										rowFilterItem.slug,
										rowChoice.nanoid,
										parseInt(i + 1),
								  )
								: rowFilterItem.question_type === 'matrix'
								? matrixQuestionRowFilter(
										rawData,
										rowFilterItem.slug,
										rowChoice.nanoid,
										rowFilterItem.columns[i].nanoid,
								  )
								: analysisFilter(rawData, rowFilterItem.slug, rowChoice.nanoid);

						if (columnFilterItem.question_type === 'ranking') {
							const colResponses = rankingQuestionColumnFilter(
								rowResponses,
								columnFilterItem.slug,
								columnChoice.nanoid,
								columnFilterItem.categoryfield_set.length,
							);
							Object.entries(colResponses).map(([key, value]) => {
								calculatedValues.push({
									columnNanoid: columnFilterItem.nanoid,
									columnChoiceNanoid: columnChoice.nanoid,
									rowNanoid: rowFilterItem.nanoid,
									rowChoiceNanoid: rowChoice.nanoid,
									matrixRowColNanoid:
										rowFilterItem.question_type === 'matrix'
											? rowFilterItem.columns[i].nanoid
											: null,
									matrixColColNanoid: null,
									rowRankId:
										rowFilterItem.question_type === 'ranking'
											? parseInt(i + 1)
											: parseInt(0),
									columnRankId: parseInt(key),
									count: value,
								});
							});
						} else if (columnFilterItem.question_type === 'matrix') {
							const colResponses = matrixQuestionColumnFilter(
								rowResponses,
								columnFilterItem.slug,
								columnChoice.nanoid,
								columnFilterItem.columns,
							);

							Object.entries(colResponses).map(([key, value]) => {
								calculatedValues.push({
									columnNanoid: columnFilterItem.nanoid,
									columnChoiceNanoid: columnChoice.nanoid,
									rowNanoid: rowFilterItem.nanoid,
									rowChoiceNanoid: rowChoice.nanoid,
									matrixRowColNanoid:
										rowFilterItem.question_type === 'matrix'
											? rowFilterItem.columns[i].nanoid
											: null,
									matrixColColNanoid: key,
									rowRankId:
										rowFilterItem.question_type === 'ranking'
											? parseInt(i + 1)
											: parseInt(0),
									columnRankId: parseInt(0),
									count: value,
								});
							});
						} else {
							const columnResponses = analysisFilter(
								rowResponses,
								columnFilterItem.slug,
								columnChoice.nanoid,
							);

							calculatedValues.push({
								columnNanoid: columnFilterItem.nanoid,
								columnChoiceNanoid: columnChoice.nanoid,
								rowNanoid: rowFilterItem.nanoid,
								rowChoiceNanoid: rowChoice.nanoid,
								matrixRowColNanoid:
									rowFilterItem.question_type === 'matrix'
										? rowFilterItem.columns[i].nanoid
										: null,
								matrixColColNanoid: null,
								rowRankId:
									rowFilterItem.question_type === 'ranking'
										? parseInt(i + 1)
										: parseInt(0),
								columnRankId: parseInt(0),
								count: columnResponses.length,
							});
						}
					});
				});
			});
		});
	});
	return calculatedValues;
}

// TODO: This is a temporary fix to a backend issue
export const hiddenCategories = [
	'bolge',
	'sehir',
	'ilce',
	'cocuk-says',
	'cocuk-yas',
	'meslek-grubu',
];

export const overrideHiddenCategories = {
	tWUMB7ceWD4wVG36: ['meslek-grubu'],
	vesL73CdcP9k3ykc: ['meslek-grubu'],
	cMBXXNmrusA1CLkh: ['meslek-grubu'],
	OFxgBTZYlY43Nx12: ['meslek-grubu'],
	'9iyqYtYpNID-PvJ8': ['meslek-grubu'],
	'OPhcB-fU-kPdMDFb': ['sehir', 'meslek-grubu'],
	'EGE-5JAJF7p8RRgO': ['meslek-grubu'],
	'3X0qXTJE2iTiLTNq': ['segment-ses', 'meslek-grubu'],
	EdM97FTD075TNba2: ['meslek-grubu'],
	zHb517ZYZZatMJsO: ['meslek-grubu'],
	nvsXqkBVpSPl0eFz: ['meslek-grubu'],
	'ZPe1CilYAkpv-afM': ['sehir', 'meslek-grubu'],
	cRlfROX2IFJaSzLq: ['meslek-grubu'],
	'JibGq7-bgfwx9ySu': ['meslek-grubu'],
	'nWL5-atA03slCoyU': ['meslek-grubu'],
	XZ8Gut2wQWdTM9zv: ['meslek-grubu'],
	FVkbSZ0BNsJEchu0: ['bolge', 'meslek-grubu'],
	EfIoKvcJPiLkjWAy: ['bolge', 'meslek-grubu'],
	FErmvjZcEJb2nTPa: ['meslek-grubu'],
	'tmDWpFUFdVee-PF8': ['meslek-grubu'],
	RnYrjjMppCmerimd: ['meslek-grubu'],
	Lq3edsNBvnxPYUNC: ['meslek-grubu'],
	PnfMdBGiGoWmfTHv: ['meslek-grubu'],
	VHtLtKFBAEm9rUWS: ['meslek-grubu'],
	'Qd-SA6vKIuq7QBm1': ['meslek-grubu'],
	fU1hmTzoMGK3cXLp: ['meslek-grubu'],
	A15v8jQx4NUYI9fy: ['meslek-grubu'],
	'1-wi4ZLibnRT-UZ9': ['meslek-grubu'],
	PB1Z9fRQn2HLk1ea: ['meslek-grubu'],
	dca07TrM4xXtO0ti: ['meslek-grubu'],
	'8xOGMe9M-T4yszQQ': ['meslek-grubu'],
	DVl3qSDr4MKHFJYc: ['meslek-grubu'],
	rAA47xcnxlRnY2vu: ['meslek-grubu'],
	'BcbxHKtvXA-CsGEq': ['meslek-grubu'],
	'2RzCwhpZEM3cP1Dt': ['meslek-grubu'],
	GubtIcHR4Nz3OWQR: ['meslek-grubu'],
	BBGB3JP9TWsL1PTq: ['meslek-grubu'],
	zLlsbXsiW0mvDcGX: ['meslek-grubu'],
	wS9dofaWKvIk4tA3: ['bolge', 'meslek-grubu'],
	BiUPm20v05LPnGsK: ['meslek-grubu'],
	'0eQgxQ03jfr8uoCU': ['meslek-grubu'],
	ZJtAFKRD7YS3Ul94: ['sehir'],
	FtJDj6e83bFicJ5d: ['bolge'],
	'74Wb5lQswbJY7MiN': ['sehir'],
	'jTNdk--iavwiTpcG': ['sehir'],
	ypIjwE7nOKP7Oo0Y: ['cocuk-says'],
	YJ4qJsbLOUSqtIVA: ['sehir'],
	'ffUWSBRGNatg-WRi': ['bolge'],
	P4DppUi0zgnNNvxP: ['sehir'],
	'vrU7nXI0cK2-nWNM': ['sehir'],
	keQupLps7WhgFy7q: ['bolge'],
	O5geHoMc7wLpKJqY: ['sehir'],
	'PpSDI-vrcDzR1wC0': ['bolge'],
	kIOUsUyM3XjHX0Go: ['bolge'],
	aWgKCXHqDIKlPct8: ['sehir'],
};

/*
	In a Project (nanoid below), we had duplicate choices in a question.
	We merge their responses in Analysis saga and below are the hardcoded nanoids.
	We should remove this in the future and fix the issue in backend.
*/
export const ELCA_PROJECT_NANOID = 'ZC9yKxw4yejQxcUq';
export const ELCA_PROBLEMATIC_QUESTION_NANOID = 'I0fbH8oJz9ywL94P';
export const ELCA_PROBLEMATIC_QUESTION_WRONG_CHOICE_NANOID = 'CWLUAvMSwCWD4LCY';
export const ELCA_PROBLEMATIC_QUESTION_RIGHT_CHOICE_NANOID = 'FglXh0llWvOJOijq';

export const calculateHeightForBarChart = (
	choiceLength,
	isCompareMode = false,
	comparisonItemCount,
) => {
	let height = 60 * 16; //h-[60rem]
	if (choiceLength < 3) height = 44 * 4; //h-44
	else if (choiceLength >= 3 && choiceLength < 5) height = 52 * 4; //h-52
	else if (choiceLength >= 5 && choiceLength < 10) height = 64 * 4; //h-64
	else if (choiceLength >= 10 && choiceLength < 20) height = 96 * 4; //h-96
	const legendHeight = comparisonItemCount * 20;

	//returns pixel value
	return isCompareMode ? height + legendHeight : height;
};

export function calculateArithmeticMean(numbers) {
	// Check if the array is not empty
	if (numbers.length === 0) {
		return 'N/A';
	}

	// Sum up all the values in the array
	let sum = numbers.reduce(
		(accumulator, currentValue) => accumulator + currentValue,
		0,
	);

	// Divide the sum by the number of elements
	let mean = sum / numbers.length;

	// Return the mean
	return parseFloat(mean.toFixed(4));
}

export function calculateMedian(numbers) {
	// Check if the array is empty
	if (numbers.length === 0) {
		return 'N/A';
	}

	// Sort the array in ascending order
	let sortedNumbers = numbers.slice().sort((a, b) => a - b);

	// Calculate the middle index
	let middleIndex = Math.floor(sortedNumbers.length / 2);

	// Check if the array has an odd or even number of elements
	let result = null;
	if (sortedNumbers.length % 2 !== 0) {
		// Return the middle element for an odd number of elements
		result = sortedNumbers[middleIndex];
	} else {
		// Return the average of the two middle elements for an even number of elements
		result = (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
	}
	return parseFloat(result.toFixed(4));
}

export function calculateStandardDeviation(numbers, type = 'S') {
	// Check if the array is not empty
	if (numbers.length === 0) {
		return 'N/A';
	}

	// For sample standard deviation, we need at least two elements
	if (type === 'S' && numbers.length < 2) {
		return 'N/A';
	}

	// Calculate the mean
	let mean = numbers.reduce((acc, val) => acc + val, 0) / numbers.length;

	// Calculate the variance
	let variance = numbers.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0);

	// Adjust the divisor depending on the type (population 'P' or sample 'S')
	variance /= type === 'S' ? numbers.length - 1 : numbers.length;

	// Calculate the standard deviation (square root of variance)
	let standardDeviation = Math.sqrt(variance);

	return parseFloat(standardDeviation.toFixed(4));
}
